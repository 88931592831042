.will-change {
  will-change: transform;
}

.page-backside {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: perspective(2500px) rotateY(0deg);
  z-index: 2;
  backface-visibility: hidden;
}

.page-backside--top {
  background-image: $top-gradient-back-side;
  left: 0;
}

.page-backside--about {
  left: 120%;
  background-image: $about-gradient-back-side;
}

.page-backside--contact {
  left: -120%;
  background-image: $contact-gradient-back-side;
}

.page-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  transform-origin: center center;
  transform: perspective(2500px) rotateY(180deg);
  z-index: 1;
  backface-visibility: hidden;
  overflow: hidden;
}

.page-wrapper--top {
  left: 0;
  background-image: $top-gradient;
}

.page-wrapper--about {
  left: 120%;
  background-image: $about-gradient;
}

.page-wrapper--contact {
  left: -120%;
  background-image: $contact-gradient;
}

.page-inner {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;

  &.is-scrollable {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@keyframes ripple-animation {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  40% {
    opacity: .2;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}

.ripple {
  position: absolute;
  display: block;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
  filter: blur(2px);
  @include screen-pc {
    width: 120px;
    height: 120px;
  }
  &.is-animating {
    animation-name: ripple-animation;
    animation-duration: .8s;
    animation-fill-mode: forwards;
    transition-timing-function: linear;
  }
}
