.contact-content-wrapper {
  position: relative;
  @include screen-pc {
    padding-top: 50px;
    padding-bottom: 120px;
  }
  @include screen-sp {
    padding-top: 30px;
    padding-bottom: 90px;
  }
}

.contact-text-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include screen-pc {
    position: absolute;
    top: 50px;
    right: 0;
    width: 40%;
    height: 150px;
    padding-left: 50px;
  }
  @include screen-sp {
    height: 100px;
    padding-left: 30%;
  }
  &.is-visible {
    > p > span {
      transform: translateY(0);
    }
    > ul > li {
      &:after {
        opacity: 1;
        filter: blur(0);
      }
      > span {
        transform: translateY(0);
      }
    }
  }
  > p {
    overflow: hidden;
    > span {
      display: inline-block;
      transform: translateY(100%);
      transition: all .6s ease-out;
      @include screen-pc {
        font-size: 22px;
      }
      @include screen-sp {
        font-size: 20px;
      }
    }
  }
  > ul {
    margin-top: 5px;
    padding-left: 3px;
    list-style: none;
    > li {
      position: relative;
      padding-left: 10px;
      font-weight: bold;
      overflow: hidden;
      &:not(:first-child) {
        margin-top: 5px;
      }
      &:nth-child(1) {
        &:after { transition-delay: .4s; }
        > span { transition-delay: .2s; }
      }
      &:nth-child(2) {
        &:after { transition-delay: .6s; }
        > span { transition-delay: .4s; }
      }
      &:nth-child(3) {
        &:after { transition-delay: .8s; }
        > span { transition-delay: .6s; }
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 8px;
        width: 4px;
        height: 4px;
        background-color: #000;
        transition: all .6s ease-out;
        opacity: 0;
        filter: blur(5px);
      }
      > span {
        display: inline-block;
        font-size: 14px;
        transform: translateY(100%);
        transition: all .6s ease-out;
      }
    }
  }
}

.contact-service {
  position: relative;
  z-index: 3;
}

.contact-service__item {
  position: relative;
  @include screen-pc {
    height: 150px;
  }
  @include screen-sp {
    height: 100px;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 1px);
    height: 100%;
    background-color: #fff;
    transform: scaleX(1);
    transition: all 1s cubic-bezier(.61,.03,.3,.99);
  }
  &.is-visible {
    &:after {
      transform: scaleX(0);
    }
    > a {
      > img {
        opacity: 1;
        filter: blur(0);
      }
    }
  }
  > a {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    @include screen-pc {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        display: block;
        width: calc(100% - 180px);
        height: 1px;
        background-color: #fff;
        transform: scaleX(0);
        transition: all .6s cubic-bezier(.61,.03,.3,.99);
      }
      &:hover{
        &:after {
          transform: scaleX(1);
        }
      }
    }
    > img {
      position: relative;
      display: block;
      opacity: 0;
      filter: blur(10px);
      transition: all .6s ease-out;
      transition-delay: .8s;
      z-index: 2;
      @include screen-pc {
        width: 80px;
      }
      @include screen-sp {
        width: 50px;
      }
    }
  }
}

.contact-service__item--twitter {
  @include screen-pc {
    width: 60%;
    background-image: linear-gradient(to right, #982E81 0%, #6A358D 100%);
  }
  @include screen-sp {
    width: 85%;
    margin-top: 30px;
    background-image: linear-gradient(to right, #982E81 0%, #573891 100%);
  }
  &:after {
    left: 0;
    transform-origin: right center;
  }
  > a {
    padding-right: 50px;
    justify-content: flex-end;
    @include screen-pc {
      &:after {
        left: 0;
        transform-origin: left center;
      }
    }
  }
}

.contact-service__item--linkedin {
  width: 70%;
  margin-left: auto;
  background-image: linear-gradient(to left, #4C3A94 0%, #813186 100%);
  @include screen-pc {
    margin-top: 50px;
  }
  @include screen-sp {
    margin-top: 30px;
  }
  &:after {
    right: 0;
    transform-origin: left center;
  }
  > a {
    justify-content: flex-start;
    padding-left: 50px;
    @include screen-pc {
      &:after {
        right: 0;
        transform-origin: right center;
      }
    }
  }
}

.contact-form-line {
  position: absolute;
  top: 0;
  width: 2px;
  transform-origin: center top;
  transform: scaleY(0);
  transition: transform .8s cubic-bezier(.61,.03,.3,.99);
  z-index: 2;
  @include screen-pc {
    left: 20%;
    height: 782px;
    background-color: #893185;
  }
  @include screen-sp {
    left: 15%;
    height: 752px;
    background-color: #8D3084;
  }
  &.is-visible {
    transform: scaleY(1);
  }
}

.contact-form {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 2px;
  z-index: 1;
  @include screen-pc {
    width: 60%;
    background-image: linear-gradient(to right, #893185 0%, #5B3890 100%);
  }
  @include screen-sp {
    width: 70%;
    background-image: linear-gradient(to right, #8D3084 0%, #573891 100%);
  }
  input {
    width: 100%;
    padding: 8px 10px;
    background-color: #fff;
    font-size: 14px;
  }
  input, textarea {
    transition: color .3s ease-out;
    &.is-complete {
      color: #979797;
    }
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #fff;
    transform: scaleY(1);
    transform-origin: center center;
    transition: all .3s cubic-bezier(.61,.03,.3,.99);
    transition-delay: .8s;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: scaleX(1);
    transform-origin: right center;
    transition: all .8s cubic-bezier(.61,.03,.3,.99);
  }
  &.is-visible {
    &:before {
      transform: scaleY(0);
    }
    &:after {
      transform: scaleX(0);
    }
  }
}

.contact-form--name {
  @include screen-pc {
    margin-top: 50px;
  }
  @include screen-sp {
    margin-top: 30px;
  }
}

.contact-form--email {
  margin-top: 30px;
}

.contact-form--textarea {
  height: 200px;
  margin-top: 30px;

  textarea {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    background-color: #fff;
    font-size: 14px;
    padding: 10px;
  }
}

.contact-form__complete-ele {
  position: absolute;
  top: 2px;
  left: 2px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: $gray-op;
  font-weight: bold;
  opacity: 0;
  filter: blur(10px);
  @include screen-pc {
    font-size: 18px;
  }
  @include screen-sp {
    padding: 0 15px;
    font-size: 16px;
  }
}

.contact-form__error-message {
  position: absolute;
  bottom: -1px;
  left: 10px;
  text-align: center;
  font-size: 12px;
  transform: translateY(100%);
  color: $error-txt;
  & + .contact-form__error-message {
    transform: translateY(200%);
  }
}

@keyframes contact-btn-hover {
  0% {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(1.2) scaleY(1.6);
  }
}

.contact-btn {
  position: relative;
  width: 240px;
  height: 40px;
  opacity: 0;
  filter: blur(10px);
  transition: all .6s ease-out;
  transform: translate3d(0,0,0); // Safariでのパフォーマンス改善
  @include screen-pc {
    margin: 40px auto 0;
  }
  @include screen-sp {
    margin: 30px auto 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 1;
    background-image: $main-gradient;
    transform-origin: center center;
  }
  &:hover {
    &:after {
      animation-name: contact-btn-hover;
      animation-duration: .6s;
      animation-fill-mode: forwards;
      transition-timing-function: linear;
    }
  }
  &.is-visible {
    opacity: 1;
    filter: blur(0);
  }
  > button {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-image: $main-gradient;
    color: #fff;
    overflow: hidden;
    z-index: 2;
  }
}


.contact-confirm__overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.8);
  opacity: 0;
  z-index: 4;
}

.contact-confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  padding: 5px;
  max-width: 680px;
  transform: translate(-50%, -50%);
  background-image: $main-gradient;
  z-index: 4;
  @media screen and (max-width: 375px) {
    width: calc(100% - 40px);
  }
}

.contact-confirm__scroll-wrapper {
  max-height: 80vh;
  overflow-y: auto;
}

.contact-confirm__inner {
  background-color: #fff;
  @include screen-pc {
    padding: 50px 30px;
  }
  @include screen-sp {
    padding: 40px 20px;
  }
}

.contact-confirm__txt {
  font-weight: bold;
  @include screen-pc {
    text-align: center;
    font-size: 18px;
  }
}

.contact-confirm__content {
  margin-top: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  word-break: break-word;
  @include screen-pc {
    padding: 25px 30px;
  }
  @include screen-sp {
    padding: 20px 25px;
  }
  > dl {
    &:not(:first-child) {
      margin-top: 15px;
    }
    > dt, > dd {
      padding-left: 12px;
    }
    > dt {
      position: relative;
      font-weight: bold;
      font-size: 12px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: #000;
        top: 4px;
      }
    }
    > dd {
      margin-top: 5px;
      font-size: 16px;
    }
  }
  > p {
    margin-top: 15px;
  }
}

.confirm-btn-wrapper {
  @include screen-pc {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  @include screen-sp {
    margin-top: 20px;
    .confirm-btn + .confirm-btn {
      margin-top: 15px;
    }
  }
}

.confirm-btn {
  position: relative;
  height: 40px;
  margin: 40px auto 0;
  @include screen-pc {
    width: 240px;
  }
  @include screen-sp {
    max-width: 240px;
    width: 100%;
    @media screen and (max-width: 375px) {
      max-width: 100%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 1;
    transform-origin: center center;
  }

  &:hover {
    &:after {
      animation-name: contact-btn-hover;
      animation-duration: .6s;
      animation-fill-mode: forwards;
      transition-timing-function: linear;
    }
  }

  > button {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    z-index: 2;
  }
}

.confirm-btn--cancel {
  margin-top: 0;

  &:after {
    background-color: #cecece;
  }

  > button {
    background-color: #cecece;
  }
}

.confirm-btn--send {
  margin-top: 0;

  &:after {
    background-image: $main-gradient;
  }

  > button {
    background-image: $main-gradient;
  }
}

.contact-modal-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.contact-modal-loader__circle {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 50%;
  border: solid 16px $gray-op;
  animation-name: loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  &:after {
    content: '';
    position: absolute;
    top: -16px;
    left: calc(50% - 8px);
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-image: $main-gradient;
  }
}

.contact-modal-complete-message {
  position: fixed;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  font-size: 20px;
  font-weight: bold;
}

.contact-modal-error-message {
  position: fixed;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
  color: $error-txt;
  text-align: center;
}

@keyframes error-btn-hover {
  0% {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(1.2) scaleY(1.4);
  }
}

.contact-modal-error-message__btn {
  position: relative;
  width: 100px;
  height: 40px;
  margin-top: 30px;
  background: $error-txt;
  border-radius: 20px;
  > span {
    position: relative;
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    z-index: 2;
  }
  @include screen-pc {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: $error-txt;
      border-radius: 20px;
      z-index: 1;
    }
    &:hover {
      &:after {
        animation-name: error-btn-hover;
        animation-duration: .6s;
        animation-fill-mode: forwards;
        transition-timing-function: linear;
      }
    }
  }
}
