#first-view {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 3;

  .cover {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1px;
    transform-origin: left center;
    transform: scaleX(0);
    background-image: $main-gradient;
  }

  .first-view-logo {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    .first-view-logo-inner {
      width: 60vw;
      max-width: 240px;
      margin: 0 auto;
    }

    .first-view-logo-svg-wrapper {
      position: relative;
      width: 100%;
      padding-top: calc(39 / 245 * 100%);
      > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        filter: blur(0);
        opacity: 0;
        transform: translateY(100%);
      }
    }
  }

  .object {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 160px;
    transform: translate(-50%, -50%);

    &.is-spinning {
      animation-name: object-spin;
      animation-duration: 3s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
    }

    svg {
      transform-origin: center center;
      transform: scale(0);
    }
  }
  @keyframes object-spin {
    0% { transform: translate(-50%, -50%) rotate(0deg) }
    100% { transform: translate(-50%, -50%) rotate(270deg) }
  }

  .line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    svg {
      transform-origin: left center;
      transform: scaleX(0);
      opacity: .5;
    }
  }

  .balls {
    position: absolute;
    top: 0;
    left: 0;
    width: 264px; // 80 * 3 + 12 * 2
    height: 264px; // 80 * 3 + 12 * 2
    transform: perspective(500px);
    z-index: 10;
    transform-origin: center center;
    transform-style: preserve-3d;

    &.is-transforming {
      transform: perspective(500px) rotateY(406deg) rotateZ(7deg) rotateX(43deg);
    }
  }
  .ball {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: perspective(500px) scale(0);
    &:nth-child(1) { .item { top: 0;    left: 100%; transform: translate(-100%, 0);} }
    &:nth-child(2) { .item { top: 0;    left: 50%;  transform: translate(-50%, 0);} }
    &:nth-child(3) { .item { top: 50%;  left: 100%; transform: translate(-100%, -50%);} }
    &:nth-child(4) { .item { top: 0;    left: 0;    transform: translate(0, 0);} }
    &:nth-child(5) { .item { top: 50%;  left: 50%;  transform: translate(-50%, -50%);} }
    &:nth-child(6) { .item { top: 100%; left: 100%; transform: translate(-100%, -100%);} }
    &:nth-child(7) { .item { top: 50%;  left: 0;    transform: translate(0, -50%);} }
    &:nth-child(8) { .item { top: 100%; left: 50%;  transform: translate(-50%, -100%);} }
    &:nth-child(9) { .item { top: 100%; left: 0;    transform: translate(0, -100%);} }
    // 右の列
    &:nth-child(1),&:nth-child(3),&:nth-child(6) {
      .item-inner {
        background-image: $about-gradient-back-side;
      }
    }
    // 真ん中の列
    &:nth-child(2),&:nth-child(5),&:nth-child(8) {
      .item-inner {
        background-image: $top-gradient-back-side;
      }
    }
    // 左の列
    &:nth-child(4),&:nth-child(7),&:nth-child(9) {
      .item-inner {
        background-image: $contact-gradient-back-side;
      }
    }
  }

  .item {
    position: absolute;
    width: 80px;
    height: 80px;
  }

  .item-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
