$main-gradient: linear-gradient(to right, #4B3A94 0%, #852B90 26%, #F43B3B 60%, #F3AB69 100%);
$main-gradient-vertical: linear-gradient(to top, #4B3A94 0%, #852B90 26%, #F43B3B 60%, #F3AB69 100%);

$contact-gradient-back-side: linear-gradient(to right, #4C3A94 0%, #982E81 100%);
$contact-gradient: linear-gradient(to right, #982E81 0%, #4C3A94 100%);

$top-gradient-back-side: linear-gradient(to right, #9D2F7E 0%, #F43C3C 100%);
$top-gradient: linear-gradient(to right, #F43C3C 0%, #9D2F7E 100%);

$about-gradient-back-side: linear-gradient(to right, #F44E43 0%, #F3AA68 100%);
$about-gradient: linear-gradient(to right, #F3AA68 0%, #F44E43 100%);

$gray-op: #ececec91;
$error-txt: #ef1138;
