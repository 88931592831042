.about-wrapper {
  position: relative;
  overflow: hidden
}

.about-bg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.about-bg {
  position: absolute;
  top: 0;
  left: 0;
}

.about-bg-square {
  position: absolute;
  top: -3px;
  left: 0;
  min-width: 100%;
  height: 1px;
  mix-blend-mode: multiply;
  font-size: 0;
  > img {
    display: block;
    margin: 0 auto;
  }
}

@keyframes move-to-right {
  0% {
    transform: translate3d(-100vw,0,0);
  }
  100% {
    transform: translate3d(100vw,0,0);
  }
}

.about-bg-square--01 {
  animation: 20s linear infinite both move-to-right;
  > img {
    width: 30vw;
  }
}

.about-bg-square--02 {
  animation: 35s linear infinite both move-to-right;
  > img {
    width: 60vw;
  }
}

.about-bg-square--03 {
  animation: 60s linear infinite both move-to-right;
  > img {
    width: 240vw;
  }
}

.about-content-wrapper {
  max-width: 960px;
  margin: 0 auto;
  height: 100%;

  @include screen-pc {
    padding: 0 60px;
  }

  @include screen-sp {
    padding: 0 20px;
  }
}

.about-port {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  user-select: none;
}

.about-port__circle-wrapper {
  position: relative;
  margin: 0 auto;
  @include screen-pc {
    width: 160px;
    height: 160px;
  }
  @include screen-sp {
    width: 140px;
    height: 140px;
  }
}

@keyframes about-port-circle-flash {
  0% {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
  50% {
    opacity: 0;
    transform: scaleX(1.4) scaleY(1.4);
  }
  100% {
    opacity: 0;
    transform: scaleX(1.4) scaleY(1.4);
  }
}

.about-port__circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 0;
  transform: translate3d(0,0,0);
  z-index: 5;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: $about-gradient;
    opacity: 0;
    z-index: 0;
  }
  &.is-flashed {
    &:after {
      animation-name: about-port-circle-flash;
      animation-duration: 1.2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-delay: 1.4s;
      transition-timing-function: linear;
    }
  }
  @include screen-pc {
    &:hover {
      .about-port__circle__balloon.is-balloon-active-pc {
        transform: translate(-50%, calc(-100% - 20px)) scale(1);
      }
    }
  }
}

.about-port__circle__balloon {
  position: absolute;
  top: 0;
  left: 50%;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  border: 2px solid #F3AA68;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  transform: translate(-50%, calc(-100% - 20px)) scale(0);
  transform-origin: center bottom;
  transition: transform .4s;
  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: calc(50% - 8px);
    display: block;
    width: 15px;
    height: 15px;
    background-color: #F3AA68;
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: calc(50% - 8px);
    display: block;
    width: 15px;
    height: 15px;
    background-color: #fff;
    transform: rotate(45deg);
  }
  @include screen-pc {
    width: 320px;
  }
  @include screen-sp {
    width: 290px;
    &.is-balloon-active-sp {
      transform: translate(-50%, calc(-100% - 20px)) scale(1);
    }
  }
}

.about-port__circle__backside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: $about-gradient-back-side;
  border-radius: 50%;
  backface-visibility: hidden;
  transform: perspective(500px) rotateY(0deg);
  transition: transform .5s;
  z-index: 1;

  &.is-flipped {
    transform: perspective(500px) rotateY(180deg);
  }
}

.about-port__circle__front {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: $about-gradient;
  border-radius: 50%;
  backface-visibility: hidden;
  transform: perspective(500px) rotateY(-180deg);
  transition: transform .5s;
  z-index: 1;

  &.is-flipped {
    transform: perspective(500px) rotateY(0deg);
  }
}

.about-port__circle__img {
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  font-size: 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  > img {
    user-select: none;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.about-port__light-wrapper {
  position: absolute;
  display: block;
  z-index: 3;
  opacity: 0;
  @include screen-pc {
    width: 70px;
  }
  @include screen-sp {
    width: 50px;
  }
  > img {
    user-select: none;
  }
  // 長タップで保存するのを禁止
  > span {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
}
.about-port__light {
  display: none;
  position: absolute;
  background-color: #F3AA68;
  opacity: .8;
  border-radius: 100% 5px 5px 5px / 100% 5px 5px 5px;
  transform: skew(10deg, 10deg);
  transform-origin: right bottom;
  z-index: 1;
  @include screen-pc {
    width: 220px;
    height: 220px;
    top: -210px;
    left: -210px;
  }
  @include screen-sp {
    width: 160px;
    height: 160px;
    top: -160px;
    left: -160px;
  }
}

.about-port__message {
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  font-weight: 300;
  transition: .6s all;
  transition-delay: .3s;
  text-align: center;
  mix-blend-mode: soft-light;
  z-index: 3;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }

  @include screen-pc {
    margin-top: 25px;
    font-size: 20px;
    .only-sp {
      display: none;
    }
  }

  @include screen-sp {
    margin-top: 15px;
    font-size: calc(17 / 375 * 100vw);
    @media screen and (min-width: 560px) {
      font-size: 25px;
    }
  }

  > a {
    cursor: default;
  }
}

.about-ttl {
  @include screen-pc {
    font-size: 48px;
    margin-bottom: 20px;
  }
}

.about-prof {
  @include screen-pc {
    padding: 80px 0 40px;
  }
}

.about-prof__lead {
  line-height: 1.6;
  @include screen-pc {
    margin-top: 15px;
    font-size: 16px;
  }

  @include screen-sp {
    margin-top: 12px;
    font-size: 14px;
  }
}

.about-prof__info {
  position: relative;
  background-image: $main-gradient-vertical;

  @include screen-pc {
    margin-top: 60px;
  }
  @include screen-sp {
    margin-top: 35px;
  }
}

.about-prof__info__cover-left {
  @include screen-sp {
    display: none;
  }
  position: absolute;
  top: 0;
  left: -1px;
  width: 50%;
  height: 100%;
  z-index: 1;
  background: #fff;
}

.about-prof__info__cover-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  background: #fff;
  @include screen-pc {
    width: 50%;
  }
  @include screen-sp {
    width: calc(100% - 1px);
  }
}

.about-prof__info__row {
  position: relative;
  z-index: 1;

  @include screen-pc {
    display: flex;
  }

  @include screen-sp {
    padding-left: 15px;
  }

  & + .about-prof__info__row {
    @include screen-pc {
      margin-top: 10px;
    }

    @include screen-sp {
      margin-top: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      background-color: #fff;
      width: 3px;
      @include screen-pc {
        top: -10px;
        left: calc(50% - 2px);
        height: 10px;
      }
      @include screen-sp {
        top: -20px;
        left: -1px;
        height: 20px;
      }
    }
  }
}

.about-prof__info__col {
  @include screen-pc {
    display: flex;
    align-items: center;
    width: 50%;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  &:nth-child(1) {
    @include screen-pc {
      justify-content: flex-end;
      padding-right: 50px;
    }
  }

  &:nth-child(2) {
    @include screen-pc {
      padding-left: 50px;
    }
  }
}

.about-prof__info__item {
  font-weight: 300;
  @include screen-pc {
    font-size: 36px;
    text-align: right;
  }
  @include screen-sp {
    font-size: 22px;
  }
}

.about-prof__info__desc {
  @include screen-pc {
    font-size: 18px;
  }
  @include screen-sp {
    margin-top: 10px;
    font-size: 14px;
  }
  > span {
    position: relative;
    display: block;
    @include screen-pc {
      padding-left: 15px;
      & + span {
        margin-top: 7px;
      }
    }
    @include screen-sp {
      padding-left: 12px;
      & + span {
        margin-top: 6px;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      border-style: solid;
      border-color: transparent transparent transparent #000;
      @include screen-pc {
        top: 8px;
        border-width: 3px 0 3px 5px;
      }
      @include screen-sp {
        top: 5px;
        border-width: 2px 0 2px 3px;
      }
    }
  }
}

.about-works {
  @include screen-pc {
    padding: 40px 0 80px;
  }
  @include screen-sp {
    padding: 50px 0 80px;
  }
}

.about-works__content {
  @include screen-pc {
    margin-top: 40px;
  }
  @include screen-sp {
    margin-top: 20px;
  }
}

.about-works__item {
  user-select: none;
  &:not(:first-child) {
    margin-top: 60px;
  }
}

.about-works__name {
  position: relative;
  @include screen-pc {
    font-size: 24px;
    padding-left: 20px;
  }
  @include screen-sp {
    font-size: 18px;
    padding-left: 15px;
  }
}

.about-works__name__arrow {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  border-style: solid;
  border-color: transparent transparent transparent #000;
  transform: translateY(-50%);
  @include screen-pc {
    border-width: 5px 0 5px 9px;
  }
  @include screen-sp {
    border-width: 4px 0 4px 7px;
  }
}

.about-works__desc {
  > p {
    line-height: 1.6;
    @include screen-pc {
      margin-top: 15px;
      font-size: 16px;
      & + p {
        margin-top: 10px;
      }
    }
    @include screen-sp {
      margin-top: 12px;
      font-size: 14px;
      & + p {
        margin-top: 8px;
      }
    }
    a {
      text-decoration: underline;
      color: #F44E43;
      @include screen-pc {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.about-works__img {
  font-size: 0;
  margin-top: 20px;
}
