@mixin screen-pc {
  @media screen and (min-width: 769px) {
    @content
  }
}

@mixin screen-sp {
  @media screen and (max-width: 768px) {
    @content
  }
}
