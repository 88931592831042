.top-content-wrapper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-logo {
  text-align: center;
  opacity: 0;
  font-size: 0;
  margin-left: auto;
  margin-right: auto;

  @include screen-pc {
    width: calc(320 / 1440 * 100vw);
    transform: translateY(100%);
  }

  @include screen-sp {
    width: calc(240 / 375 * 100vw);
    transform: translateY(100%);
  }
  > img {
    width: 100%;
  }
}

.top-content-section {
  opacity: 0;
}

.top-name {
  text-align: center;
  color: #333;
  font-weight: bold;
  overflow: hidden;

  @include screen-pc {
    margin-top: calc(30 / 1440 * 100vw);
    font-size: calc(24 / 1440 * 100vw);
  }

  @include screen-sp {
    margin-top: calc(15 / 375 * 100vw);
    font-size: calc(18 / 375 * 100vw);
  }

  > span {
    display: block;
    transform: translateY(100%);
  }
}

.top-description {
  display: block;
  overflow: hidden;
  text-align: center;
  color: #333;
  white-space: nowrap;
  font-weight: 300;

  @include screen-pc {
    margin-top: calc(10 / 1440 * 100vw);
    font-size: calc(18 / 1440 * 100vw);
  }

  @include screen-sp {
    margin-top: calc(8 / 375 * 100vw);
    font-size: calc(14 / 375 * 100vw);
  }

  > span {
    display: block;
    transform: translateY(100%);
  }
}
