.menu-wrapper {
  position: fixed;
  bottom: 20px;
  left: 50%;
  display: none;
  width: 0; // 最終的にPC50, SP45
  height: 0; // 最終的にPC50, SP45
  border-radius: 50%;
  transform-origin: center center;
  transform: translateX(-50%);
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 2px 6px -1px #787878;
}

.menu-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: $main-gradient;
  z-index: 1;
}

@keyframes line-animation {
  0% {
    transform-origin: left center;
    transform: scaleX(0);
  }
  20% {
    transform-origin: left center;
    transform: scaleX(1);
  }
  60% {
    transform-origin: right center;
    transform: scaleX(1);
  }
  80% {
    transform-origin: right center;
    transform: scaleX(0);
  }
  100% {
    transform-origin: right center;
    transform: scaleX(0);
  }
}

.menu-open-btn {
  position: fixed;
  bottom: 20px;
  left: 50%;
  border-radius: 50%;
  transform-origin: center center;
  transform: translateX(-50%) scale(0);
  opacity: 1; // 開いたときは0になる
  z-index: 4;

  @include screen-pc {
    width: 50px;
    height: 50px;
  }

  @include screen-sp {
    width: 45px;
    height: 45px;
  }

  > span {
    position: absolute;
    display: block;
    height: 1px;
    background-color: #fff;
    transform: scaleX(0);
    &:nth-child(2) {
      animation-delay: .2s;
    }
    &:nth-child(3) {
      animation-delay: .4s;
    }
    @include screen-pc {
      width: 24px;
      left: 13px;
      &:nth-child(1) {
        top: 17px;
      }
      &:nth-child(2) {
        top: 25px;
      }
      &:nth-child(3) {
        top: 33px;
      }
    }
    @include screen-sp {
      width: 23px;
      left: 11px;
      &:nth-child(1) {
        top: 15px;
      }
      &:nth-child(2) {
        top: 22px;
      }
      &:nth-child(3) {
        top: 29px;
      }
    }
    &.is-animating {
      animation-name: line-animation;
      animation-duration: 2s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
  &.is-hover-active {
    &:hover {
      & + .open-btn-hover-item {
        animation-name: btn-hover;
        animation-duration: .6s;
        animation-fill-mode: forwards;
        transition-timing-function: linear;
      }
    }
  }
}

@keyframes btn-hover {
  0% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(1.5);
  }
}

.open-btn-hover-item {
  position: fixed;
  bottom: 20px;
  left: 50%;
  border-radius: 50%;
  transform-origin: center center;
  transform: translateX(-50%) scale(1);
  z-index: 1;
  background-image: $main-gradient;
  opacity: 0;
  transition: all .3s;

  @include screen-pc {
    width: 50px;
    height: 50px;
  }

  @include screen-sp {
    width: 45px;
    height: 45px;
  }
}

.menu-close-btn {
  position: fixed;
  bottom: 20px;
  left: 50%;
  border-radius: 50%;
  transform: translateX(-50%) scale(0);
  background-color: #fff;
  z-index: 3;
  @include screen-pc {
    width: 50px;
    height: 50px;
    &:hover {
      > img {
        transform: scale(.7);
      }
    }
  }
  @include screen-sp {
    width: 45px;
    height: 45px;
  }
  > img {
    position: absolute;
    transform-origin: center center;
    transition: all .3s;
    @include screen-pc {
      width: 23px;
      top: 15px;
      left: 13px;
    }
    @include screen-sp {
      top: 13px;
      left: 12px;
      width: 21px;
    }
  }
}

.menu-list {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  width: 84%;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 2;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);

    > a {
      position: relative;
      display: none;
      color: #fff;
      font-weight: 300;
      transition: letter-spacing .3s;
      @include screen-pc {
        &:hover {
          letter-spacing: 0.3em;
        }
      }
    }
  }
}

.menu-list__txt {
  opacity: 0;
  @include screen-pc {
    font-size: 64px;
  }
  @include screen-sp {
    font-size: 9vw;
    @media screen and (min-width: 415px) {
      font-size: 36px;
    }
  }
}

@keyframes menu-list-visible {

}

.menu-list__line {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #fff;
  transform-origin: center center;
  transform: translate(-50%, -50%) scaleX(0);
}
